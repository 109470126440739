<template>
  <div class="review_plan">
    <div class="plan_tag">
      <p class="plan_tag_l">
        <span class="tag_01" @click="() => {goPage('/cases')}">{{$t('casesDetail.casesChildren.common.bl')}}</span> &gt;
        <span class="tag_02" @click="() => {goPage('/cases/detail')}">{{$t('casesDetail.casesChildren.common.blxq')}}</span> > {{$t('casesDetail.casesChildren.reviewPlan.fhzlfa')}}
      </p>
      <p class="plan_tag_r" @click="goBack">&lt;&lt; {{$t('casesDetail.casesChildren.common.fhsyy')}}</p>
    </div>
    <div class="plan_head">
      <span class="plan_h_strong">{{$t('casesDetail.casesChildren.reviewPlan.blh')}}：{{casesInfo.caseNumber}}</span>
      <span class="plan_h_sp">{{casesInfo.clinicName}}</span>
      <span class="plan_h_sp">{{casesInfo.productName}}</span>
    </div>
    <div class="plan_h_con">
      <div class="plan__con_left">
        <div class="p__c_lpc" :style="{backgroundImage: `url('${casesInfo.photo ? $PicPrefix + casesInfo.photo : defPhoto}')`}">
        </div>
        <div class="p__c_lc">
          <h5 class="p__c_lc_name">
            {{casesInfo.realName}}
            <span
              class="lc_name_icon"
              :style="{backgroundImage: `url('${casesInfo.sex === '1' ? BOY_ICON : GIRL_ICON}')`}"
            ></span>
          </h5>
          <p class="p__c_lc_p">
            <span class="r_sr_icon"></span>
            {{casesInfo.age}}（{{casesInfo.birthdayStr}}）
            <span class="r_m_icon"></span>
            {{casesInfo.mobile}}
          </p>
          <p class="p__c_lc_p">
            <span class="r_d_icon"></span>
            {{casesInfo.address}}
          </p>
        </div>
      </div>
      <div class="plan__con_right">
        <div class="plan__con_r_h">
          <p class="r_h_l_p">{{planMsg.updateDatetime || '-'}}</p>
        </div>
        <p class="plan__con_r_con">{{planMsg.secondName}}</p>
        <div class="plan__con_r_foo">
          <span class="main_big_btn main_theme_color_btn_white" @click="toAmendments(planMsg.secondName)">{{$t('casesDetail.casesChildren.reviewPlan.tjxgyj')}}</span>
          <span class="main_big_btn main_theme_color_btn" @click="confirmOk">{{$t('casesDetail.casesChildren.reviewPlan.pzcfa')}}</span>
        </div>
      </div>
    </div>
    <operation-view-con @getPlanMsg="getPlanMsg" isType="0"/>
    <confirm-modal
      :confirmObj="{
        tipText: $t('casesDetail.casesChildren.reviewPlan.sfjxpz'),
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t('casesDetail.casesChildren.reviewPlan.rrpz')
      }"
      @confirmSubmit="confirmSubmit"
      @confirmHide="confirmHide"
      v-show="isShowConfirm"
    />
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import {confirmCurePlan, casesDetail} from 'common/api/cases';
  import {defPhoto, BOY_ICON, GIRL_ICON} from 'common/js/requireImage';
  import {notifyMsg} from 'common/js/util';
  import FullLoading from 'components/full-loading/full-loading';
  import ConfirmModal from 'components/confirm-modal/confirm';
  import OperationViewCon from './operationChildren/operationViewCon';
  export default {
    data() {
      return {
        defPhoto,
        BOY_ICON,
        GIRL_ICON,
        isShowConfirm: false,
        isLoading: false,
        casesInfo: {},
        planMsg: {}
      }
    },
    created() {
      document.title = this.$t('casesDetail.casesChildren.reviewPlan.fhzlfa');
      const details = localStorage.getItem('casesDetail') || sessionStorage.getItem('casesDetail') || '';
      sessionStorage.setItem('casesDetail', details);
      localStorage.removeItem('casesDetail');
      sessionStorage.removeItem('cureSummary');
      const casesId = localStorage.getItem('casesId') || sessionStorage.getItem('casesId') || '';
      const curePlanId = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId') || '';
      localStorage.removeItem('casesId');
      localStorage.removeItem('curePlanId');
      sessionStorage.setItem('casesId', casesId);
      sessionStorage.setItem('curePlanId', curePlanId);
      if (details) {
        const data = JSON.parse(details);
        this.initParams(data);
      }else {
        const casesId = localStorage.getItem('casesId') || sessionStorage.getItem('casesId') || '';
        localStorage.removeItem('casesId');
        sessionStorage.removeItem('casesId');
        sessionStorage.setItem('casesId', casesId);
        if(casesId) {
          casesDetail(casesId).then(data => {
            this.initParams(data);
          });
        }
      }
    },
    methods: {
      initParams(data) {
        const {productName, clinicId, clinicName, caseNumber, productionFeeFlag} = data;
        const {realName, photo, address = {}, age, birthdayStr, mobile, sex} = data.userInfo;
        const {provinceName = '', cityName = '', areaName = ''} = address;
        this.casesInfo = {
          caseNumber,
          clinicName,
          clinicId,
          productName,
          realName,
          photo,
          address: `${provinceName}-${cityName}-${areaName}-${address.address || ''}`,
          age,
          sex,
          birthdayStr,
          mobile,
          productionFeeFlag
        };
      },
      getPlanMsg(msg) {
        this.planMsg = msg;
      },
      confirmSubmit() {
        const curePlanId = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId');
        sessionStorage.setItem('curePlanId', curePlanId);
        localStorage.removeItem('curePlanId');
        if(!curePlanId) {
          this.isShowConfirm = false;
          return false;
        }
        this.isLoading = true;
        confirmCurePlan({
          id: curePlanId,
          approveResult: '1'
        }).then(() => {
          this.isLoading = false;
          this.isShowConfirm = false;
          notifyMsg(this, 'success', this.$t('casesDetail.casesChildren.common.czcg')).then(() => {
            const {productionFeeFlag} = this.casesInfo;
            if(productionFeeFlag === '0') {
              this.goPage('/cases/paymentScheme?payType=1');
            }else {
              this.goPage('/cases/detail');
            }
          });
        }).catch(() => {
          this.isLoading = false;
          this.isShowConfirm = false;
        });
      },
      confirmHide() {
        this.isShowConfirm = false;
      },
      confirmOk() {
        this.isShowConfirm = true;
      },
      toAmendments(name) {
        this.$router.push(`/cases/amendments?secondName=${name}`);
      },
      goPage(url) {
        this.$router.push(`${url}`);
      },
      goBack() {
        this.$router.push('/cases/detail');
      }
    },
    components: {
      ConfirmModal,
      FullLoading,
      OperationViewCon
    }
  }
</script>

<style scoped lang="scss">
  .review_plan{
    width: 13.44rem;
    margin: 0 auto;
    color: $main_theme_color_333;
    font-size: 0.16rem;
    span{
      display: inline-block;
    }
    .plan_tag{
      padding: 0.2rem 0;
      color: $main_theme_color_333;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .plan_tag_l{
        display: flex;
        align-items: center;
        .tag_01{
          margin-right: 0.04rem;
          cursor: pointer;
        }
        .tag_02{
          margin: 0 0.04rem;
          cursor: pointer;
        }
      }
      .plan_tag_r{
        color: $main_theme_color;
        cursor: pointer;
      }
    }
    .plan_head{
      display: flex;
      align-items: center;
      margin-bottom: 0.2rem;
      .plan_h_strong{
        font-size: 0.24rem;
      }
      .plan_h_sp{
        padding-left: 0.1rem;
        border-left: 0.01rem solid #B5B5B5;
        margin-left: 0.3rem;
      }
    }
    .plan_h_con{
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.4rem;
      .plan__con_left{
        flex: 1;
        margin-right: 0.2rem;
        background-color: #fff;
        border-radius: 0.08rem;
        padding: 0 0.4rem;
        display: flex;
        align-items: center;
        .p__c_lpc{
          min-width: 1.02rem;
          width: 1.02rem;
          height: 1.02rem;
          border-radius: 100%;
          margin-right: 0.3rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
        .p__c_lc{
          .p__c_lc_name{
            font-size: 0.22rem;
            margin-bottom: 0.2rem;
            display: flex;
            align-items: center;
            .lc_name_icon{
              width: 0.18rem;
              height: 0.18rem;
              margin-left: 0.08rem;
              background-size: 100% 100%;
            }
          }
          .p__c_lc_p{
            display: flex;
            line-height: 0.24rem;
            color: #666;
            &:nth-of-type(1){
              margin-bottom: 0.2rem;
            }
          }
        }
      }
      .plan__con_right{
        width: 4.6rem;
        background-color: #fff;
        border-radius: 0.06rem;
        padding: 0.3rem 0.3rem 0.28rem;
        box-sizing: border-box;
        .plan__con_r_h{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 0.22rem;
          .r_h_l_p{
            font-size: 0.14rem;
            color: #999;
          }
          .con_r_h_r{
            font-size: 0.22rem;
          }
        }
        .plan__con_r_con{
          padding: 0.14rem 0;
          border-radius: 1rem;
          background-color: #F6F6F6;
          color: #666666;
          font-size: 0.14rem;
          text-align: center;
          margin-bottom: 0.22rem;
          margin-left: 0.13rem;
        }
        .plan__con_r_foo{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 0.13rem;

        }
      }
    }
  }
</style>
